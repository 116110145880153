<div id="admin_setting" class="admin-page">
  <h3 class="title is-3">Admin Login</h3>
  <div class="section">
    <div class="columns is-multiline text-left">
      <div class="column is-12">
        <div class="field">
          <label class="label">Email</label>
          <div class="control has-icons-left">
            <input
              class="input"
              type="text"
              placeholder="Email"
              v-model="data.email"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <label class="label">Password</label>
        <div class="field has-addons">
          <div class="control has-icons-left is-expanded">
            <input
              class="input"
              :type="showPassword ? 'text' : 'password'"
              placeholder="password akun"
              v-model="data.password"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </div>
          <div class="control">
            <button
              class="button is-link"
              @click="showPassword = !showPassword"
            >
              <span class="icon">
                <i
                  :class="[showPassword ? 'fas fa-eye-slash' : 'fas fa-eye']"
                ></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <button class="button is-primary is-fullwidth" @click="loginByAuth">
          Sign in
        </button>
      </div>
    </div>
  </div>
</div>
